<template>
  <v-card v-if="registration && justifications.length" outlined>
    <v-toolbar flat>
      <v-toolbar-title class="mr-6">Inscription # {{ registration.id }}</v-toolbar-title>

      <v-chip
        outlined
      >
        {{ registration.registration_date | formatDate}}
      </v-chip>

      <v-spacer></v-spacer>

      <!-- eslint-disable max-len -->
      <v-btn
        color="primary"
        :href="`${baseUrl}admin/misc-get-pdf.php?type=enf&id=${registration.id}&access=${accessToken}`"
        target="_blank"
      >
        Voir le dossier
      </v-btn>
      <!-- eslint-enable max-len -->
    </v-toolbar>

    <v-card-text>
      <v-expansion-panels
        v-model="activePanels"
        focusable
        multiple
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Informations personnelles</v-expansion-panel-header>
          <v-expansion-panel-content class="pt-5">
            <v-list>
              <v-row>
              <v-col>

              <v-subheader>Identité</v-subheader>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon :color="getGenderColor(registration.gender)">
                    mdi-account
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.first_name }} {{ registration.last_name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="brown">mdi-cake</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.birth_date | formatDate }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="blue-grey">mdi-flag</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.nationality }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              </v-col>
              <v-col>

              <v-subheader>Contact</v-subheader>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="orange">mdi-email</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.email }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="light-green">mdi-phone</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.mobile }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              </v-col>
              <v-col>
              <v-subheader>Adresse</v-subheader>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="red">mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.street }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="grey">mdi-city</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.postal_code }} - {{ registration.city }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="blue">mdi-earth</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.country }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>
              </v-col>
              </v-row>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Parcours scolaire</v-expansion-panel-header>
          <v-expansion-panel-content class="pt-5">
            <div class="text-subtitle-1 d-flex justify-space-between align-center">
              Date d'obtention du diplôme

              <v-chip>
                {{ registration.graduation_date | formatDate }}
              </v-chip>
            </div>

            <div
              v-if="registration.equivalence_date"
              class="mt-6 text-subtitle-1 d-flex justify-space-between align-center"
            >
              Date d'obtention de l'équivalence

              <v-chip>
                {{ registration.equivalence_date | formatDate }}
              </v-chip>
            </div>

            <div class="mt-6 text-subtitle-1">{{ academicYears.first }}</div>

            <div class="font-italic px-4">"{{ registration.academic_background_1 }}"</div>

            <div class="mt-6 text-subtitle-1">{{ academicYears.second }}</div>

            <div class="font-italic px-4">"{{ registration.academic_background_2 }}"</div>

            <div class="mt-6 text-subtitle-1">{{ academicYears.third }}</div>

            <div class="font-italic px-4">"{{ registration.academic_background_3 }}"</div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Analyse</v-expansion-panel-header>
          <v-expansion-panel-content class="pt-5">
            <v-form
              ref="analysisForm"
              :disabled="analysisIsSaving
              || registration.validated || !isAllowedToAnalyse"
            >
              <v-radio-group
                label="Demande de l'étudiant : "
                v-model="decision"
                @change="resetJustifications"
                :rules="[(v) => !!v || '']"
                row
                hide-details
              >
                <v-radio
                  label="Accepter"
                  value="acceptance"
                  color="success"
                ></v-radio>

                <v-radio
                  label="Refuser"
                  value="refusal"
                  color="error"
                ></v-radio>
              </v-radio-group>

              <v-radio-group
                v-if="decision === 'refusal'"
                label="Règles : "
                v-model="registration.decision_rules"
                @change="hasSavedJusti = false"
                :rules="[(v) => !!v || '']"
                row
                hide-details
              >
                <v-radio
                  label="Anciennes"
                  value="old"
                ></v-radio>

                <v-radio
                  label="Nouvelles"
                  value="new"
                ></v-radio>
              </v-radio-group>

              <v-select
                label="Motivation"
                v-model="registration.justification"
                :items="availableJustifications"
                item-text="justification"
                item-value="id"
                outlined
                @change="hasSavedJusti = false; registration.custom_justification = null;"
                required
                :rules="[(v) => !!v || 'Ce champ est obligatoire']"
                :disabled="!decision"
                dense
                class="mt-4"
              ></v-select>

              <v-textarea
                v-if="[45, 53, 61, 71, 79, 85].includes(registration.justification)"
                v-model="registration.custom_justification"
                label="Motivation libre"
                outlined
                no-resize
                maxlength="2048"
                counter="2048"
                rows="3"
                required
                @input="hasSavedJusti = false"
                :rules="[(v) => !!v || 'Ce champ est obligatoire']"
                dense
              ></v-textarea>

              <template v-if="!registration.validated">
                <v-btn
                  v-if="isAllowedToAnalyse"
                  :loading="analysisIsSaving"
                  color="primary"
                  @click="saveAnalysis"
                  class="mr-4"
                >
                  Enregistrer
                </v-btn>

                <!-- eslint-disable max-len -->
                <v-btn
                  :disabled="analysisIsSaving || !hasSavedJusti"
                  class="mr-4"
                  color="secondary"
                  :href="`${baseUrl}admin/misc-preview-analysis.php?type=enf&registration_id=${registration.id}&access=${accessToken}`"
                  target="_blank"
                >
                  Afficher
                </v-btn>
                <!-- eslint-enable max-len -->

                <dialog-confirm-analyse-sending
                  :isDisplayed="isAllowedToAnalyseAsDir"
                  :isDisabled="analysisIsSaving || !hasSavedJusti"
                  :registrationId="registration.id"
                  :type="'enf'"
                  @analyse-sent="registration.validated = true"
                ></dialog-confirm-analyse-sending>
              </template>
            </v-form>

            <v-alert
              v-if="registration.validated"
              icon="mdi-check"
              text
              type="info"
              prominent
            >
              <v-row class="align-center">
                <v-col class="grow">
                  L'analyse de la demande de l'étudiant lui a été envoyée par email
                </v-col>

                <v-col class="shrink">
                  <!-- eslint-disable max-len -->
                  <v-btn
                    color="primary"
                    :href="`${baseUrl}admin/misc-preview-analysis.php?type=enf&registration_id=${registration.id}&access=${accessToken}`"
                    target="_blank"
                  >
                    Afficher
                  </v-btn>
                  <!-- eslint-enable max-len -->
                </v-col>
              </v-row>
            </v-alert>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import { getGenderColor } from '@/mixins/methodsMixin';
import DialogConfirmAnalyseSending from '@/components/notification/DialogConfirmAnalyseSending.vue';

export default {
  name: 'AdminEnfRegistrationDetails',

  components: {
    'dialog-confirm-analyse-sending': DialogConfirmAnalyseSending,
  },

  props: {
    cursusId: String,
    instanceId: String,
    registrationId: String,
    cursusDept: String,
  },

  mixins: [getGenderColor],

  data: () => ({
    decision: null,
    registration: null,
    registrationIsLoading: false,
    activePanels: [0, 1, 2],
    analysisIsSaving: false,
    justifications: [],
    hasSavedJusti: false,
  }),

  computed: {
    accessToken() {
      return this.$http.defaults.headers.common.Authorization.replace('Bearer ', '');
    },

    baseUrl() {
      return process.env.VUE_APP_AXIOS_BASE_URL;
    },

    academicYears() {
      if (!this.registration) return '';
      const registrationDateYear = new Date(this.registration.registration_date).getFullYear();
      return {
        first: `${registrationDateYear - 3} - ${registrationDateYear - 2}`,
        second: `${registrationDateYear - 2} - ${registrationDateYear - 1}`,
        third: `${registrationDateYear - 1} - ${registrationDateYear}`,
      };
    },

    availableJustifications() {
      return this.justifications.filter((justification) => justification.type === this.decision);
    },

    isAllowedToAnalyseAsGes() {
      const isGes = this.$store.state.user.roles.includes(`APP.INS.ENF.${this.cursusDept}.GES`);
      const isAdmin = this.$store.state.user.roles.includes('APP.INS.ADMIN');
      return isGes || isAdmin;
    },

    isAllowedToAnalyseAsDir() {
      const isDir = this.$store.state.user.roles.includes(`APP.INS.ENF.${this.cursusDept}.DIR`);
      const isAdmin = this.$store.state.user.roles.includes('APP.INS.ADMIN');
      return isDir || isAdmin;
    },

    isAllowedToAnalyse() {
      return this.isAllowedToAnalyseAsGes || this.isAllowedToAnalyseAsDir;
    },
  },

  async created() {
    await this.getRegistrationDetails();
    this.getAllJustifications();
  },

  methods: {
    ...mapMutations({ showNotification: 'SET_NOTIFICATION' }),

    async getRegistrationDetails() {
      await this.$store.dispatch('getAccessToken');
      try {
        this.registrationIsLoading = true;
        const response = await this.$http.get(`admin/registration-get-by-id.php?registration_id=${this.registrationId}&type=enf`);
        this.registration = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.registrationIsLoading = false;
      }
    },

    async getAllJustifications() {
      await this.$store.dispatch('getAccessToken');
      try {
        // this.registrationIsLoading = true;
        const response = await this.$http.get('admin/misc-get-justifications.php?type=ENF');
        this.justifications = response.data;
        const tmp = this.justifications.find(
          (justification) => justification.id === this.registration.justification,
        );
        if (tmp) {
          this.decision = tmp.type;
          this.hasSavedJusti = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        // this.registrationIsLoading = false;
      }
    },

    async saveAnalysis() {
      if (!this.$refs.analysisForm.validate()) return;
      await this.$store.dispatch('getAccessToken');
      try {
        this.analysisIsSaving = true;
        const response = await this.$http.post('admin/misc-save-analysis.php', {
          registration_id: this.registration.id,
          decision_rules: this.registration.decision_rules,
          justification: this.registration.justification,
          custom_justification: this.registration.custom_justification,
          type: 'enf',
        });
        this.hasSavedJusti = true;
        this.showNotification({
          isVisible: true,
          text: response.data,
          color: 'success',
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.analysisIsSaving = false;
      }
    },

    resetJustifications() {
      this.hasSavedJusti = false;
      this.registration.decision_rules = null;
      this.registration.justification = null;
      this.registration.custom_justification = null;
    },
  },
};
</script>
